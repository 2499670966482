import { Item, Wrapper } from "./styles";

import React from "react";
import images from "../../data/layouts";

export const LayoutChooser = ({ selected, onChange, ...props }) => {
	return (
		<Wrapper {...props}>
			{images.map(({ icon }, index) => {
				return (
					<Item
						image={icon}
						selected={index === selected}
						onClick={() => onChange(index)}
					/>
				);
			})}
		</Wrapper>
	);
};
